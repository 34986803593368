<template>
  <div>
    <SubmitAsset :editAssetData="asset" :parentBreadcrumbs="breadcrumbs" @saved="formatAssetData($event)" />
  </div>
</template>

<script>
import SubmitAsset from '@/views/SubmitAsset.vue';
import ApiService from '@/services/api.service';

export default {
  name: 'cms-edit-asset',
  components: {
    SubmitAsset,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name) {
        vm.breadcrumbs.push({ title: from.name, link: from.path });
        vm.breadcrumbs.push({ title: to.name, link: to.path });
      } else {
        vm.breadcrumbs = [
          { title: 'Audi Collection', link: '/portal/cms/audi-collection' },
          { title: 'Edit Asset', link: to.path },
        ];
      }
    });
  },
  data() {
    return {
      asset: {},
      breadcrumbs: [],
    };
  },
  created() {
    ApiService.get(`/assets/${this.$route.params.assetId}`).then(({ data }) => {
      const asset = data?.data?.asset;
      this.formatAssetData(asset);
    });
  },
  methods: {
    formatAssetData(asset) {
      const categoryIds = [];
      const jobRoleIds = [];
      asset.assetCategories.forEach((category) => categoryIds.push(category.id));
      asset.assetJobRoleCategories.forEach((jobRole) => jobRoleIds.push(jobRole.id));

      this.asset = {
        id: asset.id,
        name: asset.name,
        assetTypeId: asset.assetTypeId,
        s3Key: asset.s3Key,
        description: asset.description,
        isPublic: asset.isPublic,
        isDownloadEnabled: asset.isDownloadEnabled,
        useOwnerName: asset.useOwnerName,
        assetType: asset.assetType.name,
        modelYearId: asset.assetModelYear.id,
        year: asset.assetModelYear.modelYear,
        model: asset.assetModelYear.modelName,
        useAllJobRoles: asset.useAllJobRoles,
        jobRole: jobRoleIds,
        assetCategory: categoryIds,
      };
    },
  },
};
</script>
